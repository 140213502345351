import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions('User', [
      'ActionSetUsersAll',
      'ActionSetUsers',
      'ActionSetUserSelect',
      'ActionSetFilterUser',
      'ActionSetDialogUser',
      'ActionUpdateUser'
    ]),

    async getUsers(page, perPage) {
      let query;
      if (!page) query = '?page=1';
      else query = '?' + page;
      if (!perPage) query += `&perPage=${localStorage.getItem('rowsPerPage') || '&perPage=10'}`;
      else query += perPage;
      if (this.filterUser) query += `&${this.filterUser}`;
      this.onLoading(true);

      query = query.replace('+', '_');
      try {
        const { data, status } = await this.$http.get('users' + query);
        if (status === 200) {
          this.ActionSetUsersAll(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    columns() {
      return [
        {
          name: 'id',
          required: true,
          label: 'Id',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: this.$t('name'),
          field: 'name',
          sortable: true
        },
        {
          name: 'email',
          align: 'left',
          label: 'Email',
          field: 'email',
          sortable: true
        },
        {
          name: 'title',
          align: 'left',
          label: this.$t('profile'),
          field: 'title',
          sortable: true
        },
        {
          name: 'created_at',
          align: 'left',
          label: this.$t('date'),
          field: 'created_at',
          format: (val) => this.$moment(val).format('L')
        },
        {
          align: 'left',
          name: 'blocked',
          label: this.$t('blocked'),
          field: 'blocked'
        },
        {
          name: 'action',
          align: 'center',
          label: this.$t('actions'),
          field: 'id'
        }
      ];
    },
    ...mapGetters('User', ['usersAll', 'users', 'columnsUser', 'userSelect', 'filterUser', 'dialogUser'])
  }
};
